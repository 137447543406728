/* style.css */

/* Adjust the scrolling behavior and gap */
.health-tips-carousel {
  display: flex;
  overflow-x: auto;
  gap: 0px; /* Reduce gap between items */
  scroll-behavior: smooth; /* Enable smooth scrolling */
}

.healthtips {
  margin-right: 5px; /* Reduce margin between items */
}

/* Ensure images and text are styled correctly */
.healthtips-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.healthtips-text {
  text-align: center;
  font-size: 14px;
  margin-top: 5px;
}
