.online-frame {
  height: 7px;
  width: 7px;
  position: absolute;
  margin: 0 !important;
  top: -19px;
  right: 4px;
  border-radius: 50%;
  background-color: var(--color-mediumseagreen);
  z-index: 1;
}
.d2-icon4 {
  position: absolute;
  top: 0;
  left: 0;
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 50%; /* Makes the image circular */
}
.star-field {
  position: absolute;
  top: 2px;
  left: 55px;
  border-radius: 50%;
  background-color: rgb(25 135 84);
  width: 10px;
  height: 10px;
  z-index: 2;
}

.offline-star-field {
  position: absolute;
  top: 2px;
  left: 55px;
  border-radius: 50%;
  background-color: #938e8e;
  width: 10px;
  height: 10px;
  z-index: 2;
}

.busy-star-field {
  position: absolute;
  top: 2px;
  left: 55px;
  border-radius: 50%;
  background-color: rgb(255 193 7);
  width: 10px;
  height: 10px;
  z-index: 2;
}

.d2-parent2 {
  height: 75px;
  width: 75px;
  position: relative;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.ellipse2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}
.family-medicine8 {
  color: #938e8e;
  margin-top: -27px;
  margin-bottom: 42px;
  font-size: 10px;
  /* flex: 1; */
  /* position: relative;
  line-height: 0px; */
  font-weight: 500;
  z-index: 4;
}
.family-medicine9 {
  z-index: 5;
  margin-left: -101px;
}
.online8 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 43px;
  font-weight: 600;
  display: inline-block;
  width: 36px;
  height: 33px;
  z-index: 2;
}
.online9 {
  font-size: 10px;
  /* position: absolute; */
  top: 0;
  left: 0;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  width: 60px;
  height: 33px;
  z-index: 2;
}
.online-parent2 {
  width: 34px;
  height: 33px;
  position: relative;
  margin-top: -48px;
  color: green;
}

.online-parent3 {
  width: 34px;
  height: 33px;
  position: relative;
  margin-top: -48px;
  color: #a10000;
}

.online-parent4 {
  width: 34px;
  height: 33px;
  position: relative;
  margin-top: -48px;
  color: #ffc107 !important;
}

.frame-parent31 {
  width: 101px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.dr-karim-rehmat10 {
  font-family: var(--font-poppins);
  position: relative;
  line-height: 78px;
  font-weight: 600;
}
.dr-karim-rehmat11 {
  position: relative;
  line-height: 78px;
  font-weight: 600;
}
.dr-karim-rehmat11 {
  z-index: 1;
  margin-left: -105px;
}
.frame-parent30 {
  align-self: stretch;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
}
.chat-bubbles-frame,
.ellipse-pair,
.frame-parent30 {
  display: flex;
  justify-content: flex-start;
}
.chat-bubbles-frame {
  align-self: stretch;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 var(--padding-10xs);
}
.ellipse-pair {
  height: 80px;
  width: 350px;
  flex-direction: row;
  align-items: flex-end;
  gap: 0 11px;
}
.video-call-icon {
  position: absolute;
  top: 0;
  left: 20px;
  width: 10px;
  height: 9.5px;
  z-index: 1;
}
.video-call-icon1,
.video-call-icon2,
.video-call-icon3,
.video-call-icon4 {
  position: absolute;
  top: 0;
  left: 31px;
  width: 10px;
  height: 9.5px;
  z-index: 1;
}
.video-call-icon2,
.video-call-icon3,
.video-call-icon4 {
  left: 42px;
}
.video-call-icon3,
.video-call-icon4 {
  left: 53px;
}
.video-call-icon4 {
  left: 64px;
}
.family-medicine-logo-group4 {
  width: 94px;
  height: 14px;
  position: relative;
}
.call-frame {
  height: 31px;
  width: 67px;
  position: absolute;
  margin: 0 !important;
  bottom: 0px;
  left: -10px;
  border-radius: 26px;
  /* background-color: var(--color-whitesmoke-200); */
  box-shadow: 0 0 6.3px 1px rgba(0, 0, 0, 0.25);
}
.audio-video-call7 {
  margin-left: -2px;
  font-family: var(--font-poppins);
  flex: 1;
  position: relative;
  line-height: 32px;
  font-weight: 500;
  cursor: pointer;
  z-index: 1;
}

/* .text-color-info {
  color: ;
} */
.framewith-ellipses2 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}
.twinkling-stars-frame {
  /* width: 111px; */
  margin-right: -12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1px 0;
  color: var(--color-cadetblue-200);
}
.star-group {
  margin-top: -30px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}
.rectangle-parent14 {
  width: 395px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-5xl) 0 var(--padding-xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--font-size-3xs);
  color: var(--color-gray-100);
  font-family: var(--font-poppins);
}

.lines,
.lines1 {
  height: 1px;
  flex: 1;
  position: relative;
  border-top: 1px solid var(--color-silver);
  box-sizing: border-box;
}
.lines1 {
  z-index: 1;
  /* margin-left: -421px; */
}

.search-bar {
  width: 100%;
  height: 50px;
  border: none;
  border-radius: 25px;
  padding: 25px 50px;
  /* margin-left: 10px; */
  font-size: 16px;
  box-shadow: inset 3px 4px 6px 0px rgba(0, 0, 0, 0.3),
    inset 0px 0px 0px 0px rgba(255, 255, 255, 0.3),
    0px 0px 0px rgba(0, 0, 0, 0.1), 12px 12px 0px rgba(255, 255, 255, 0.5);
  outline: none;
}

.search-bar:focus {
  box-shadow: inset 3px 3px 5px rgba(0, 0, 0, 0.3),
    inset -3px -3px 5px rgba(255, 255, 255, 0.3),
    -3px -3px 5px rgba(0, 0, 0, 0.2), 3px 3px 5px rgba(255, 255, 255, 0.6);
}

.lines {
  border-top: 1px solid #cdc0c0;
  box-sizing: border-box;
  flex: 1 1;
  height: 1px;
  position: relative;
}

.custom-button {
  transition: background 0.3s ease, transform 0.3s ease;
}

.custom-button:hover {
  background: #0056b3;
  transform: scale(1.05);
}

.rtl-input {
  text-align: right;
}

.rtl-row {
  direction: rtl;
}

.rtl-card {
  text-align: right;
}

.rtl-col {
  direction: rtl;
}

.rtl-text {
  text-align: right;
}

.rtl-button {
  float: right;
}

