/* src/style.css */

.custom-card {
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    overflow: hidden;
    background-color: #fff;
  }
  .packages_card{
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    overflow: hidden;
  }
  .custom-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 12px 40px rgba(0, 0, 0, 0.2);
  }

  
  .custom-card .card-title {
    margin-bottom: 10px;
  }
  
  .custom-card .card-text {
    color: #6c757d;
  }
  .custom-card .card-title {
   font-weight: 700;
  }
  .dark-border {
    border: 1px solid #333; /* Darker border color */
  }
  
  .rounded-border {
    border-radius: 20px; /* Adjust to make the corners more rounded */
  }
  .coverage-bg{
    background-color: #a6d4dc;
  }
  .position-relative {
    position: relative;
  }
  
  .pointing-person {
    width: 100px; /* Adjust the size as necessary */
    height: auto;
    position: absolute;
  }
  
  .pointing-left {
    transform: translateX(-40%);
  }
  
  .pointing-right {
    transform: translateX(24%);
    right: 0;
  }

 /* health tips styling */
 .health-tips-carousel {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
}

.health-tips-carousel::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.healthtips {
  flex: 0 0 auto;
  margin-right: 10px;
}



.healthtips-link {
  text-decoration: none;
}

.healthtips-image {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 70%;
}

.healthtips-text {
  background-color: #FFFFFFB2;
  font-weight: 600;
  font-size: 12.23px;
  color: black;
  padding: 5px;
  text-align: left;
} 

