.my-icon .icon-card-1 {
    background-color: #1AABBA;
    ; /* Card background color */
   
  }
  
  .icon-card-1 .card-img {
    width: 75%; /* Adjust size as needed */
  }
  
  .card-body .subscribe-button-1 {
    border: none;
    border-radius: 5px; 
    color: #fff; 
    background-color: #719E19; 
  }
  
  .shadow {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adjust shadow */
  }

  .badge-success {
    background-color: #719E19;
    color: white;
    padding: 0.5em 1em;
    border-radius: 0.25em;
  }
  
  .position-absolute {
    position: absolute;
  }
  
  .top-0 {
    top: 0;
  }
  
  .start-0 {
    left: 0;
  }
  
  .m-2 {
    margin: 0.5em;
  }

  .card-body-active {
    border: 2px solid #719E19; /* Green border for active */
    padding: 1rem;
    border-radius: 0.5rem;
  }
  
  .card-body-inactive {
    border: 2px solid transparent; /* No border for inactive */
  }
  
  