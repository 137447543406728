/* src/style.css */


  
  .banner-card {
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .banner-card img {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  
  .banner-card .card-body {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  