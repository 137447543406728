.image-12-icon,
.wrapper-image-12 {
  width: 100%;
  position: absolute;
  flex-shrink: 0;
}
.image-12-icon {
  height: 100%;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  left: 0px;
  top: 0;
  transform: scale(1.447);
  position: fixed;
}
.wrapper-image-12 {
  height: 813.7px;
  margin: 0 !important;
  right: 0;
  bottom: -8px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
}
.inner-rectangle {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-6xs);
  background-color: var(--color-whitesmoke-200);
  box-shadow: 0 1px 8.4px 1px rgba(181, 179, 179, 0.25);
  width: 34px;
  height: 33.1px;
  z-index: 1;
}
.frame-child15 {
  position: absolute;
  top: 17px;
  left: 13px;
  width: 2px;
  height: 14.7px;
  object-fit: contain;
  z-index: 2;
}
.image-15-icon {
  /* height: 33px;
  width: 34px; */
  position: fixed;
}
.image-15-icon {
  height: 111.4px;
  width: 119px;
  object-fit: cover;
  z-index: 1;
}
.frame-header {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: end;
}
.image-13-icon {
  height: 60px;
  z-index: 1;
}
.image-13-wrapper,
.video-calling {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 60px 0 var(--padding-xl);
}
.video-calling {
  width: 100%;
  position: relative;
  /* background-color: var(--color-whitesmoke-200); */
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  padding: var(--padding-xl) var(--padding-mini) var(--padding-20xl)
    var(--padding-lg);
  box-sizing: border-box;
  gap: 587px 0;
}

.btn-round {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 767px) {
  .video-call-container {
    overflow: "hidden";
    height: 100vh; /* Make the container take the full height of the screen */
  }
  video {
    object-fit: cover; /* Ensure the video covers the entire area */
  }
  .video-call-container video:nth-child(2) {
    width: 100px; /* Smaller size for the remote video */
    height: 75px;
    /* margin: 1rem; */
  }
  .position-absolute.bottom-0.start-50.translate-middle-x.mb-3 {
    width: 100%; /* Full width for the button container */
    bottom: 1rem; /* Add some spacing from the bottom */
  }
  .position-absolute.bottom-0.start-50.translate-middle-x.mb-3 button {
    margin: 0 0.5rem; /* Add some margin between buttons */
  }

  .video-container {
    width: 100%;
    height: 100%;
    object-fit: contain !important;
  }
}
