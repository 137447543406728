.custom-typography {
  font-family: "Franklin Gothic";
  font-weight: 400;
  font-size: 14px;
  line-height: 22px; /* Adjust as needed */
  text-align: justify; /* Apply text alignment */
}
.custom-typography-new {
  font-family: "Franklin Gothic";
  font-weight: 400;
  font-size: 14px;
 
}

.package-price {
  font-weight: 600;
  color: #719E19;
  font-size: 25px;
  margin-bottom: -5px;
}
