body {
  overflow: hidden;
  font-family: serif;
}

.logo {
  box-shadow: 0px 4px 4px 0px #0000001f;
  background: #fff;
  height: 70px;
  width: 70%;
  border-radius: 0px 40px 40px 0px;
}
.main-div {
  /* height:120px; */
}

.bg-main-color {
  background-color: rgba(228, 228, 225, 0.719) !important;
}

.language-selector {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  color: #000;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  padding: 10px 0;
  cursor: pointer;
  width: 120px;
  max-height: 200px;
  overflow-y: auto;
}

.dropdown-menu div {
  padding: 8px 12px;
  text-align: center;
}

.dropdown-menu div:hover {
  background-color: #f0f0f0;
}

.custom-dropdown {
  position: relative;
  display: inline-block;
}

.custom-dropdown-header {
  padding: 10px;
  /* border: 1px solid #ccc;
  background-color: #fff; */
  color: #000;
  border-radius: 4px;
}

.custom-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  z-index: 1000;
}

.custom-dropdown-item {
  padding: 10px;
  cursor: pointer;
}

.custom-dropdown-item:hover {
  background-color: #f0f0f0;
}
